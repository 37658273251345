import { useEffect, useReducer, useState } from "react";

import DeviceContext from "../../Contexts/DeviceContext";
import DispatchContext from "../../Contexts/DispatchContext";
import ForexListingDataContext from "../../Contexts/ForexListingDataContext";
import {
  ListingReducerType,
  intialListingData,
  listingReducer,
} from "../../store/forexListingReducer";
import {
  addWebVitalsScript,
  closeTmWebview,
  getDevice,
  persistSpecificParamInCookie,
} from "../../utils";
import { getSessionStorageItem } from "../../utils/clientStorageUtils";
import GlobalConfigContext from "../../Contexts/GlobalConfigContext";
import {
  COOKIES,
  getPartner,
  getSessionPiiDataOpts,
  GI_SUB_DOMAIN,
  MakeMyTrip,
  MMT_SUB_DOMAIN,
  PDT_EVENTS,
  SESSION_STORAGE_KEYS,
} from "../../constants";
import { fetchingListingPageOnLocationChange } from "../../store/action";
import useSetUTMParams from "../../hooks/useSetUTMParams";
import useIsLoggedInUser from "../../hooks/useIsLoggedInUser";
import AuthModalContainer from "../AuthModalContainer";

interface IRootContainerProps {
  children: React.ReactNode;
  emperiaResponse?: ListingReducerType;
  isDesktop?: boolean;
  partner?: string | null;
  isBot?: boolean | null;
  onLoginClick?: () => void;
}
const RootContainer = ({
  children,
  emperiaResponse,
  isDesktop = true,
  partner,
  isBot,
  onLoginClick,
}: IRootContainerProps) => {
  const [isLoggedInUser, mobileNumberRequiredError]: (
    | boolean
    | null
    | void
    | { showEmailPopup: boolean }
  )[] = useIsLoggedInUser();

  useEffect(() => {
    persistSpecificParamInCookie(COOKIES.INR_SESSION_COOKIE, "true", {
      domain: getPartner() === MakeMyTrip ? MMT_SUB_DOMAIN : GI_SUB_DOMAIN,
      path: "/",
    });
  }, []);

  const delivery_details = getSessionStorageItem(
    SESSION_STORAGE_KEYS.USER_LOCATION_SESSION_KEY,
    true,
    getSessionPiiDataOpts
  );
  const [state, dispatch] = useReducer(listingReducer, {
    ...intialListingData,
    ...emperiaResponse,
    delivery_details,
  });

  const { persistUTMParamsInCookie } = useSetUTMParams();
  const [isReqIdCreated, setReqIdCreated] = useState(false);

  useEffect(() => {
    if (getSessionStorageItem(SESSION_STORAGE_KEYS.SOURCE_REACT_NATIVE, true)) {
      closeTmWebview({ openRNListingPage: true });
    }
    setReqIdCreated(true);
    persistUTMParamsInCookie(true);
    addWebVitalsScript();
  }, []);

  useEffect(() => {
    if (emperiaResponse) {
      dispatch(fetchingListingPageOnLocationChange(emperiaResponse));
    }
  }, [emperiaResponse]);

  const device = getDevice(isDesktop);
  if (!isReqIdCreated) {
    return null;
  }
  return (
    <DeviceContext.Provider value={device}>
      <DispatchContext.Provider value={dispatch}>
        <GlobalConfigContext.Provider
          value={{
            partner: getPartner(),
            isBot,
            isLoggedInUser,
            onLoginClick,
            mobileNumberRequiredError,
          }}
        >
          <ForexListingDataContext.Provider value={state}>
            {children}
            <AuthModalContainer />
          </ForexListingDataContext.Provider>
        </GlobalConfigContext.Provider>
      </DispatchContext.Provider>
    </DeviceContext.Provider>
  );
};

export default RootContainer;
