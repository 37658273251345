import { isApp } from ".";
import { SESSION_STORAGE_KEYS } from "../constants";
import { redirectInterceptHref } from "../static/utils";
import { BridgeMethods } from "./bridges";
import { ANDROID, getMobileOperatingSystem, IOS } from "./bridges/common";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "./clientStorageUtils";

declare global {
  interface Window {
    webkit: any;
    mmt_android_bridge: any;
  }
}

export const LISTING_DEEPLINK =
  "mmyt://lending?cta=webview&ctaurl=https%3A%2F%2Ftripmoney.makemytrip.com%2Fpartners%2Fmmt%2Fforex%2Flisting&mmt_forex=true&utm_medium=mmt&minver=1&mmtauthkey=mmt-auth&showHeader=false&login_required=false&weblink=true";

export async function setRNVariablesFromURL() {
  if (typeof window === "undefined") return;

  const urlParams = new URLSearchParams(window.location.search);
  const rn = urlParams.get(SESSION_STORAGE_KEYS.SOURCE_REACT_NATIVE);

  if (rn && isApp()) {
    setSessionStorageItem(SESSION_STORAGE_KEYS.SOURCE_REACT_NATIVE, rn, true);
    return;
  }

  const isRNFunnelLive =
    (await getRNFunnelFlagFromAppStorage()) &&
    isRNFunnelLiveBasedOnAppVersion();
  if (isRNFunnelLive && isApp()) {
    setSessionStorageItem(SESSION_STORAGE_KEYS.SOURCE_REACT_NATIVE, true, true);
  }
}

export const compareNonInclusiveVersions = (v1: string, v2: string) => {
  const v1Parts = v1.split(".").map(Number);
  const v2Parts = v2.split(".").map(Number);

  for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
    const v1Part = v1Parts[i] || 0;
    const v2Part = v2Parts[i] || 0;

    if (v1Part > v2Part) return 1;
    if (v1Part < v2Part) return -1;
  }

  return 0;
};

export const isRNFunnelLiveBasedOnAppVersion = () => {
  const RN_FEATURE_FLAG = true;

  if (!RN_FEATURE_FLAG) {
    return false;
  }

  const version = BridgeMethods.getAppVersion();

  const mobileOS = getMobileOperatingSystem();
  try {
    if (mobileOS.toLowerCase() === ANDROID) {
      return compareNonInclusiveVersions(version, "10.1.1") === 1;
    } else if (mobileOS.toLowerCase() === IOS) {
      return compareNonInclusiveVersions(version, "9.3.3") === 1;
    }
  } catch (e) {
    console.error("error while executing close application: " + e);
    return false;
  }

  return false;
};

export const getRNFunnelFlagFromAppStorage = async (): Promise<
  string | null
> => {
  const RN_FUNNEL_LIVE_KEY = "rnFunnelLive";
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, 500);

    (window as any).getUserSessionStorage = (type: string, value: string) => {
      if (type === RN_FUNNEL_LIVE_KEY) {
        resolve(value);
      }
    };

    if (window?.webkit?.messageHandlers?.getUserSessionStorage) {
      window?.webkit?.messageHandlers.getUserSessionStorage?.postMessage?.({
        key: RN_FUNNEL_LIVE_KEY,
      });
    } else if (window?.app_bridge?.getInSessionStorage) {
      resolve(
        window?.app_bridge?.getInSessionStorage(RN_FUNNEL_LIVE_KEY) ?? null
      );
    } else {
      resolve(null);
    }
  });
};

export function customBackHandlingForRN() {
  if (getSessionStorageItem(SESSION_STORAGE_KEYS.SOURCE_REACT_NATIVE, true)) {
    const fn = () => closeTmWebview({ openRNListingPage: true });
    window.addEventListener("popstate", fn);
    return window.removeEventListener("popstate", fn);
  }
}

export function closeTmWebview(params?: { openRNListingPage: boolean }) {
  const os = getMobileOperatingSystem().toLowerCase();
  if (
    window.webkit?.messageHandlers?.closeWebView?.postMessage !== "undefined" &&
    os === IOS
  ) {
    if (params?.openRNListingPage) {
      // for iOS, we open deeplink with clearStack true
      BridgeMethods.openDeeplink(`${LISTING_DEEPLINK}&clearStack=true`);
    } else {
      // If we dont want to open any deeplink, we close the webview
      window.webkit.messageHandlers.closeWebView.postMessage(true);
    }
  } else if (
    typeof window.mmt_android_bridge?.closeWebView !== "undefined" &&
    os === ANDROID
  ) {
    window.mmt_android_bridge.closeWebView();
    if (params?.openRNListingPage) {
      BridgeMethods.openDeeplink(LISTING_DEEPLINK);
    }
  }
}

export function redirectToListingPage(partner: string) {
  if (getSessionStorageItem(SESSION_STORAGE_KEYS.SOURCE_REACT_NATIVE, true)) {
    closeTmWebview({ openRNListingPage: true });
  } else {
    redirectInterceptHref(`/partners/${partner}/forex/listing`);
  }
}
